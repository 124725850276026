import { useEffect, useState } from "react";
import styles from "./CreateModal.module.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  createSession,
  getBtaches,
  getChapters,
  getClasses,
  getSubjects,
  getTopics,
} from "../../../Store/API/API";

function CreateModal({ setOpenCreateModal, openCreateModal }) {
  const handleClose = () => setOpenCreateModal(false);

  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    course: [{ value: batchState?.groupId, label: batchState?.batchName }],
    tutor: null,
    date: "",
    timeFrom: "",
    timeTo: "",
    class: null,
    subject: null,
    chapter: null,
    topic: "",
    mode: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);

    const tempData = {
      user_id: userState.userId,
      group_id: formData.course?.[0]?.value,
      tutor_id: formData.tutor?.value,
      fromDate: formData.date,
      fromTime: formData.timeFrom,
      toTime: formData.timeTo,
      session_class: formData.class?.value,
      session_subject: formData.subject?.value,
      session_chapter: formData.chapter?.value,
      session_topic: formData.topic?.map((item) => item.value),
      mode: formData.mode?.value || 'Online',
      batches: formData.course?.map((item) => item.value),
    };

    console.log("Temp Data:", tempData);

    const data = await createSession(tempData, userState.token, dispatch);

    console.log("Data:", data);
    
    
  };

  const [dropDownOptions, setDropDownOptions] = useState({
    batch: [{ value: batchState?.groupId, label: batchState?.batchName }],
    tutor: [
      { value: "Tutor A", label: "Tutor A" },
      { value: "Tutor B", label: "Tutor B" },
    ],
    class: [
      { value: "X", label: "X" },
      { value: "XI", label: "XI" },
      { value: "XII", label: "XII" },
    ],
    subject: [
      { value: "Physics", label: "Physics" },
      { value: "Chemistry", label: "Chemistry" },
      { value: "Math", label: "Math" },
    ],
    chapter: [
      { value: "Chapter 1", label: "Chapter 1" },
      { value: "Chapter 2", label: "Chapter 2" },
    ],
    mode: [
      { value: "Online", label: "Online" },
      { value: "Offline", label: "Offline" },
    ],
  });

  const SelectInput = ({
    label,
    dropdownOptions,
    icon,
    required,
    placeholder,
    field,
    isMulti,
  }) => {
    return (
      <div className={styles.selectWrapper}>
        <label className={styles.label}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
        <div className={styles.inputContainer}>
          {icon && (
            <img loading="lazy" src={icon} alt="" className={styles.icon} />
          )}
          <Select
            defaultValue={[dropdownOptions?.[0]]}
            isMulti={isMulti}
            name="colors"
            options={dropdownOptions}
            className="basic-multi-select"
            placeholder={placeholder}
            value={formData[field]}
            onChange={(selectedOption) =>
              handleInputChange(field, selectedOption)
            }
            styles={{
              control: (base) => ({
                ...base,
                border: "none", // Remove border
                boxShadow: "none", // Remove default focus outline
                width: "100%", // Set width to 100%
              }),
              container: (base) => ({
                ...base,
                width: "100%", // Ensures container also spans full width
              }),
            }}
          />
        </div>
      </div>
    );
  };

  const DateInput = ({ label, field, required }) => {
    return (
      <div className={styles.timeWrapper}>
        <label className={styles.label}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
        <div className={styles.inputContainer}>
          <input
            type="date"
            className={styles.timeInput}
            value={formData[field] || ""}
            onChange={(e) => handleInputChange(field, e.target.value)}
          />
        </div>
      </div>
    );
  };

  const TimeInput = ({ label, field, required }) => {
    return (
      <div className={styles.timeWrapper}>
        <label className={styles.label}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
        <div className={styles.inputContainer}>
          <input
            type="time"
            className={styles.timeInput}
            value={formData[field] || ""}
            onChange={(e) => handleInputChange(field, e.target.value)}
          />
        </div>
      </div>
    );
  };

  const fetchBatches = async () => {
    const data = await getBtaches(
      userState.token,
      userState.userId,
      "",
      "",
      dispatch
    );

    if (data) {
      const selectedBatch = data?.filter(
        (item) => item?.group_id === batchState?.groupId
      );

      const tutors = selectedBatch?.[0]?.tutor;

      const tutorData = tutors?.map((item) => ({
        value: item?.user_id,
        label: item?.first_name,
      }));

      setDropDownOptions((prev) => ({
        ...prev,
        batch: data?.map((item) => ({
          value: item?.group_id,
          label: item?.group_name,
        })),
        tutor: tutorData,
      }));
    }
  };

  const fetchTopics = async ({ value }) => {
    const data = await getTopics(
      formData.class.value,
      formData.subject.value,
      value,
      dispatch
    );

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        topic: data,
      }));
    }
  };

  const fetchChapters = async ({ value }) => {
    const data = await getChapters(formData.class.value, value, dispatch);
    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        chapter: data,
      }));
    }
  };

  const fetchSubjects = async ({ value }) => {
    const data = await getSubjects(value, dispatch);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        subject: data,
      }));
    }
  };

  const fetchClasses = async () => {
    const data = await getClasses(dispatch);

    if (data) {
      setDropDownOptions((prev) => ({
        ...prev,
        class: data,
      }));
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));

    if (field === "class") {
      setFormData((prev) => ({
        ...prev,
        subject: null,
        chapter: null,
        topic: "",
      }));
      fetchSubjects(value);
    }

    if (field === "subject") {
      setFormData((prev) => ({
        ...prev,
        chapter: null,
        topic: "",
      }));
      fetchChapters(value);
    }

    if (field === "chapter") {
      setFormData((prev) => ({
        ...prev,
        topic: "",
      }));
      fetchTopics(value);
    }
  };

  useEffect(() => {
    // fetching batches
    fetchBatches();
    // fetching classes
    fetchClasses();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.crossIcon}></div>
          <header className={styles.header}>
            <div className={styles.headerContainer}>
              <p className={styles.title}>Create Live Sessions</p>
            </div>
          </header>

          <form className={styles.form}>
            <SelectInput
              label="Select Course"
              placeholder="Select Batch"
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/d3a1c55656a37b94e40dec5775ede8ab72164373dfbd3252bd9c95d7a545f0fe?placeholderIfAbsent=true&apiKey=65c3b0a390fd4311b72fcd91fc18e875"
              dropdownOptions={dropDownOptions?.batch}
              field={"course"}
              isMulti={true}
              required
            />

            <div className={styles.grid}>
              <DateInput
                label="Session Date"
                placeholder="Select date"
                icon="https://cdn.builder.io/api/v1/image/assets/TEMP/142bf89b75926edc803a4c8538be1d12a70b9dcc35fa5d08edd4c782ee37e85a?placeholderIfAbsent=true&apiKey=65c3b0a390fd4311b72fcd91fc18e875"
                required
                field={"date"}
              />
              <SelectInput
                label="Select Tutor"
                placeholder="Select Tutor"
                field={"tutor"}
                dropdownOptions={dropDownOptions?.tutor}
                required
              />
              <TimeInput label="Time (From)" field={"timeFrom"} required />
              <TimeInput label="Time (To)" field={"timeTo"} required />
              <SelectInput
                label="Class"
                placeholder="Select Class"
                dropdownOptions={dropDownOptions?.class}
                required
                field={"class"}
              />
              <SelectInput
                label="Subject"
                placeholder="Select Subject"
                field={"subject"}
                dropdownOptions={dropDownOptions?.subject}
                required
              />
            </div>

            <div className={styles.grid}>
              <SelectInput
                label="Mode"
                placeholder="Mode"
                field={"mode"}
                dropdownOptions={dropDownOptions?.mode}
                required
              />
              <SelectInput
                label="Chapter"
                placeholder="Select chapter"
                field={"chapter"}
                dropdownOptions={dropDownOptions?.chapter}
                required
              />
            </div>

            <SelectInput
              label="Select Topics"
              placeholder="Select Topics"
              icon="addtopic.svg"
              dropdownOptions={dropDownOptions?.topic}
              field={"topic"}
              isMulti={true}
              required
            />

            <div className={styles.actions}>
              <button type="button" className={styles.cancelButton}>
                Cancel
              </button>
              <button
                type="submit"
                className={styles.submitButton}
                onClick={handleSubmit}
              >
                Create Session
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreateModal;

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import Typography from "@mui/material/Typography";
import AppsIcon from "@mui/icons-material/Apps";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useNavigate, useLocation } from "react-router-dom";
import { DynamicFeed, Quiz, VideoLabel } from "@mui/icons-material";
import { getNotifications, userLogout } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "../../../Store/Data/Slices/UserDataSlice";
import { Tooltip } from "@mui/material";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import AIButton from "../../UI/AIButton/AIButton";
import useNetwork from "../../CustomHooks/useNetwork";
import NoInternet from "../NoInternet/NoInternet";
import styles from "./Drawer.module.css";

const drawerWidth = 240;

const width = document.body.clientWidth;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#00122E",
  color: "#ffffff",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "#00122E",
  color: "#ffffff",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  backgroundColor: "#00122E", // Set background color for header
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Footer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: width > 600 ? "row" : "column",
  alignItems: width > 600 ? "center" : "flex-end",
  justifyContent: "space-between",
  padding: theme.spacing(1, 2),
  backgroundColor: "#00122E",
  color: "white",
  position: "absolute",
  bottom: 0,
  width: "100%",
  fontSize: "0.8rem",
}));

export default function MiniDrawer({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [notificationData, setNotificationData] = React.useState([]);
  // const [footerHeight, setFooterHeight] = React.useState(0);
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const alertState = useSelector((state) => state.applicationData.alertData);
  const dispatch = useDispatch();
  React.useEffect(() => {
    console.log("User State", userState);
    console.log("Batch State", batchState);

    // eslint-disable-next-line
  }, []);

  // change favicon according to school
  const hostname = window.location.hostname;
  // console.log(hostname, "hostname");

  const school = hostname.split(".")[0];
  // const school = "dpsg";
  // console.log(school, "school");

  const changeFavicon = (iconUrl, title) => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = iconUrl;
    document.getElementsByTagName("head")[0].appendChild(link);

    if (title) {
      document.title = title;
    }
  };

  React.useEffect(() => {
    if (school === "dpsg")
      // changeFavicon("dpsg.png", "Delhi Public School Ghaziabad");
      changeFavicon("dpsg.png", "GSAT-2025");
  }, [school]);

  // React.useEffect(() => {
  //   setFooterHeight(document.getElementById("footer").offsetHeight);
  // }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const networkStatus = useNetwork();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNotificationOpen = async () => {
    if (!openNotification) {
      const data = await getNotifications(userState.userId, dispatch);
      // console.log("Notification Data", data.notifications);
      setNotificationData(data.notifications);
    }
    setOpenNotification(!openNotification);
  };

  // console.log("Location", location.pathname, location);
  // console.log("host", window.location);

  const NotificationContainer = ({ notificationHeading, createdAt }) => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <i
            className="fa-solid fa-comment fa-2xl"
            style={{ color: "#000E8F75", margin: "25px 0px" }}
          ></i>
          <div>
            <p
              style={{
                marginLeft: "15px",
                marginTop: "10px",
                fontSize: "14px",
              }}
            >
              {notificationHeading}
            </p>
            <p
              style={{
                marginLeft: "15px",
                marginTop: "-14px",
                fontSize: "10px",
                color: "grey",
              }}
            >
              - {createdAt}
            </p>
          </div>
        </div>
        <div
          style={{
            height: "1px",
            margin: "0px 0px 0px -50px",
            width: "400px",
            backgroundColor: "grey",
          }}
        ></div>
      </div>
    );
  };

  const handleLogut = async () => {
    const data = await userLogout(userState.token, dispatch);
    if (data?.status) {
      localStorage.removeItem("userData");
      localStorage.removeItem("batchData");
      dispatch(userDataActions.setUserData({}));
      dispatch(applicationActions.setBatchData({}));
      navigate("/");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <AppBar
          style={{ backgroundColor: "#ffffff" }}
          position="fixed"
          open={open}
        >
          <Toolbar>
            <IconButton
              color="black"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: width > 600 ? "36px" : "0px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {school !== "dpsg" ? (
              <img
                src="/images/qa.svg"
                className={
                  width > 600
                    ? "w-[10rem] h-12 object-contain"
                    : "w-[9rem] h-10 object-contain"
                }
                alt=""
              />
            ) : (
              <div className={styles.logoContainer}>
                <img
                  src={`${school}.jpg`}
                  alt={`${school}-logo`}
                  className={styles.entryLogo}
                />
                <img
                  src={"TGT.png"}
                  alt={`${school}-logo`}
                  className={styles.TGTLogo}
                />
              </div>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <IconButton color="black" onClick={handleNotificationOpen}>
              <NotificationsNoneIcon />
            </IconButton>
            <div
              style={{
                border: "2px solid #E5E5E5",
                borderRadius: "5px",
                width: "100px",
                height: "50px",
                marginBottom: "10px",
                borderRight: "none",
                borderTop: "none",
                borderBottom: "none",
                paddingLeft: "10px",
                paddingTop: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "300px",
                }}
              >
                <p
                  style={{
                    width: "100%",
                    margin: 0,
                    color: "red",
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                >
                  {`${userState.firstName} ${
                    userState.lastName
                  } (${userState.userType
                    .slice(0, 1)
                    .toUpperCase()}${userState.userType.slice(1)})`}
                </p>
                {userState.userType === "student" && (
                  <p
                    style={{
                      width: "100%",
                      margin: 0,
                      color: "black",
                      fontSize: "10px",
                      fontWeight: 500,
                    }}
                  >
                    Student Id: <strong>{userState.userId}</strong>
                  </p>
                )}
                {userState.userType === "teacher" && (
                  <p
                    style={{
                      width: "100%",
                      margin: 0,
                      color: "black",
                      fontSize: "10px",
                      fontWeight: 500,
                    }}
                  >
                    Teacher Id: <strong>{userState.userId}</strong>
                  </p>
                )}
                <p
                  style={{
                    width: "100%",
                    margin: 0,
                    color: "black",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  Institute Id: <strong>{userState.instituteId}</strong>
                </p>
              </div>
            </div>
            <IconButton color="black">
              <AccountCircleIcon />
            </IconButton>
          </Toolbar>
          {width >= 500 && !alertState.isAlert && (
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: batchState?.batchColor || "white",
                padding: "5px 20px",
                borderRadius: "5px",
                boxShadow:
                  "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
              }}
              onClick={() => {
                navigate("/batch");
              }}
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                sx={{ color: "black", fontWeight: 700 }}
              >
                {batchState?.batchName || "None"}
              </Typography>
              <Typography
                variant="p"
                noWrap
                component="div"
                sx={{
                  color: "red",
                  fontWeight: 700,
                  fontSize: "0.34rem",
                  alignSelf: "center",
                }}
              >
                Click to change
              </Typography>
            </div>
          )}
          {openNotification && (
            <div
              className="notification-slide-in"
              style={{
                position: "absolute",
                bottom: -530,
                right: 5,
                backgroundColor: "white",
                padding: "10px 25px",
                borderRadius: "5px",
                boxShadow:
                  "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                display: "flex",
                flexDirection: "column",
                width: "365px",
                height: "500px",
                color: "black",
                fontSize: "16px",
                zIndex: 999,
                overflow: "hidden",
              }}
              onClick={() => {
                // navigate("/batch");
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  marginTop: "15px",
                  marginBottom: "20px",
                  lineHeight: "2px",
                  textAlign: "left",
                }}
              >
                Your Notifications
              </p>
              <div
                style={{
                  height: "1px",
                  margin: "0px 0px 0px -30px",
                  width: "400px",
                  backgroundColor: "grey",
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "500px",
                  overflow: "scroll",
                  alignItems: "flex-start",
                }}
              >
                {notificationData.length > 0 ? (
                  notificationData.map((notification, index) => (
                    <NotificationContainer
                      notificationHeading={notification.notification_text}
                      createdAt={notification.created_time}
                      key={index}
                    />
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <p>No Notifications Found</p>
                  </div>
                )}
              </div>
            </div>
          )}

          <style>
            {`
              @keyframes slideIn {
                from {
                  transform: translateX(100%);
                }
                to {
                  transform: translateX(0);
                }
              }

              .notification-slide-in {
                animation: slideIn 0.5s ease-out; 
              }
            `}
          </style>

          {alertState.isAlert && (
            <div
              className={`alert alert-${alertState.alertType}`}
              role="alert"
              style={{
                position: "absolute",
                bottom: -60,
                right: 5,
                width: "20%",
              }}
            >
              {alertState.alertMessage}
            </div>
          )}
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "#00122E",
              color: "#ffffff", // Optional: Set text color
              position: "relative", // Ensure footer is inside the drawer
            },
          }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose} sx={{ color: "#ffffff" }}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider sx={{ backgroundColor: "#ffffff" }} />
          <List>
            <ListItem
              disablePadding
              sx={{ display: "block", backgroundColor: "#00122E" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&:hover": {
                    backgroundColor: "#F36421",
                  },
                  backgroundColor: "#00122E",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "yellow",
                  }}
                >
                  <WavingHandIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`Hi, ${userState.firstName} ${userState.lastName}`}
                  sx={{
                    opacity: open ? 1 : 0,
                    fontWeight: 500,
                    color: "yellow",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{
                display: "block",
                backgroundColor:
                  location.pathname === "/" ? "#F36421" : "#00122E",
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
                borderTopRightRadius: open ? "0px" : "5px",
                borderBottomRightRadius: open ? "0px" : "5px",
                margin: "10px",
                width: open ? "calc(100% - 30px)" : "calc(100% - 20px)",
              }}
            >
              <Tooltip title={"Dashboard"} placement="right" arrow>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: "#F36421",
                    },
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    <SpaceDashboardIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Dashboard"
                    sx={{
                      opacity: open ? 1 : 0,
                      fontWeight: 500,
                      color: "white",
                    }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
          <Divider sx={{ backgroundColor: "#ffffff" }} />
          {open && (
            <Typography
              variant="subtitle1"
              sx={{ margin: "10px 20px", color: "white" }}
            >
              ACADEMIC
            </Typography>
          )}
          <List>
            <ListItem key={"Batches"} disablePadding sx={{ display: "block" }}>
              <Tooltip title={"Batches"} placement="right" arrow>
                <ListItemButton
                  sx={{
                    display: "flex",
                    backgroundColor:
                      location.pathname === "/batch" ? "#F36421" : "#00122E",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    borderTopRightRadius: open ? "0px" : "5px",
                    borderBottomRightRadius: open ? "0px" : "5px",
                    padding: "5px 10px",
                    margin: "5px 10px",
                    width: open ? "calc(100% - 30px)" : "calc(100% - 20px)",
                    "&:hover": {
                      backgroundColor: "#F36421",
                    },
                  }}
                  onClick={() => {
                    navigate("/batch");
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "#ffffff",
                    }}
                  >
                    <AppsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Batches"}
                    sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
            {school !== "dpsg" && (
              <ListItem
                key={"Courses"}
                disablePadding
                sx={{ display: "block" }}
              >
                <Tooltip title={"Courses"} placement="right" arrow>
                  <ListItemButton
                    sx={{
                      display: "flex",
                      backgroundColor:
                        location.pathname === "/courses"
                          ? "#F36421"
                          : "#00122E",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      borderTopRightRadius: open ? "0px" : "5px",
                      borderBottomRightRadius: open ? "0px" : "5px",
                      padding: "5px 10px",
                      margin: "5px 10px",
                      width: open ? "calc(100% - 30px)" : "calc(100% - 20px)",
                      "&:hover": {
                        backgroundColor: "#F36421",
                      },
                    }}
                    onClick={() => {
                      navigate("/courses");
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "#ffffff",
                      }}
                    >
                      <AutoStoriesIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Courses"}
                      sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            )}

            {userState.userType !== "teacher" && school !== "dpsg" && (
              <ListItem
                key={"Payment"}
                disablePadding
                sx={{ display: "block" }}
              >
                <Tooltip title={"Payment"} placement="right" arrow>
                  <ListItemButton
                    sx={{
                      display: "flex",
                      backgroundColor:
                        location.pathname === "/payment"
                          ? "#F36421"
                          : "#00122E",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      borderTopRightRadius: open ? "0px" : "5px",
                      borderBottomRightRadius: open ? "0px" : "5px",
                      padding: "5px 10px",
                      margin: "5px 10px",
                      width: open ? "calc(100% - 30px)" : "calc(100% - 20px)",
                      "&:hover": {
                        backgroundColor: "#F36421",
                      },
                    }}
                    onClick={() => {
                      navigate("/payment");
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "#ffffff",
                      }}
                    >
                      <CurrencyRupeeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Payment"}
                      sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            )}
            {userState.userType === "teacher" && (
              <ListItem
                key={"Questions"}
                disablePadding
                sx={{ display: "block" }}
              >
                <Tooltip title={"Questions"} placement="right" arrow>
                  <ListItemButton
                    sx={{
                      display: "flex",
                      backgroundColor:
                        location.pathname === "/questions"
                          ? "#F36421"
                          : "#00122E",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      borderTopRightRadius: open ? "0px" : "5px",
                      borderBottomRightRadius: open ? "0px" : "5px",
                      padding: "5px 10px",
                      margin: "5px 10px",
                      width: open ? "calc(100% - 30px)" : "calc(100% - 20px)",
                      "&:hover": {
                        backgroundColor: "#F36421",
                      },
                    }}
                    onClick={() => {
                      navigate("/question-bank");
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "#ffffff",
                      }}
                    >
                      <QuestionMarkIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Payment"}
                      sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            )}
          </List>
          <Divider sx={{ backgroundColor: "#ffffff" }} />
          {batchState?.groupId && (
            <div>
              <List>
                {open && (
                  <Typography
                    variant="subtitle1"
                    sx={{ margin: "10px 20px", color: "white" }}
                  >
                    BATCH SECTION
                  </Typography>
                )}
                {school !== "dpsg" && (
                  <ListItem
                    key={"Posts"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <Tooltip title={"Posts"} placement="right" arrow>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          backgroundColor:
                            location.pathname === "/post"
                              ? "#F36421"
                              : "#00122E",
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: open ? "0px" : "5px",
                          borderBottomRightRadius: open ? "0px" : "5px",
                          padding: "5px 10px",
                          margin: "5px 10px",
                          width: open
                            ? "calc(100% - 30px)"
                            : "calc(100% - 20px)",
                          "&:hover": {
                            backgroundColor: "#F36421",
                          },
                        }}
                        onClick={() => {
                          navigate("/post");
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#ffffff",
                          }}
                        >
                          <DynamicFeed />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Posts"}
                          sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                )}
                {school !== "dpsg" && (
                  <ListItem
                    key={"Assignments"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <Tooltip title={"Assignments"} placement="right" arrow>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          backgroundColor:
                            location.pathname === "/assignment"
                              ? "#F36421"
                              : "#00122E",
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: open ? "0px" : "5px",
                          borderBottomRightRadius: open ? "0px" : "5px",
                          padding: "5px 10px",
                          margin: "5px 10px",
                          width: open
                            ? "calc(100% - 30px)"
                            : "calc(100% - 20px)",
                          "&:hover": {
                            backgroundColor: "#F36421",
                          },
                        }}
                        onClick={() => {
                          navigate("/assignment");
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#ffffff",
                          }}
                        >
                          <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Assignments"}
                          sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                )}
                <ListItem
                  key={"Tests"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <Tooltip title={"Tests"} placement="right" arrow>
                    <ListItemButton
                      sx={{
                        display: "flex",
                        backgroundColor:
                          location.pathname === "/testList"
                            ? "#F36421"
                            : "#00122E",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                        borderTopRightRadius: open ? "0px" : "5px",
                        borderBottomRightRadius: open ? "0px" : "5px",
                        padding: "5px 10px",
                        margin: "5px 10px",
                        width: open ? "calc(100% - 30px)" : "calc(100% - 20px)",
                        "&:hover": {
                          backgroundColor: "#F36421",
                        },
                      }}
                      onClick={() => {
                        navigate("/testList");
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "#ffffff",
                        }}
                      >
                        <Quiz />
                      </ListItemIcon>
                      <ListItemText
                        primary={"Tests"}
                        sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
                {school !== "dpsg" && (
                  <ListItem
                    key={"Session"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <Tooltip title={"Session"} placement="right" arrow>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          backgroundColor:
                            location.pathname === "/upcomming-session"
                              ? "#F36421"
                              : "#00122E",
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: open ? "0px" : "5px",
                          borderBottomRightRadius: open ? "0px" : "5px",
                          padding: "5px 10px",
                          margin: "5px 10px",
                          width: open
                            ? "calc(100% - 30px)"
                            : "calc(100% - 20px)",
                          "&:hover": {
                            backgroundColor: "#F36421",
                          },
                        }}
                        onClick={() => {
                          navigate("/upcomming-session");
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#ffffff",
                          }}
                        >
                          <VideoLabel />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Sessions"}
                          sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                )}
                {school !== "dpsg" && (
                  <ListItem
                    key={"Resources"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <Tooltip title={"Resources"} placement="right" arrow>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          backgroundColor:
                            location.pathname === "/resources"
                              ? "#F36421"
                              : "#00122E",
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: open ? "0px" : "5px",
                          borderBottomRightRadius: open ? "0px" : "5px",
                          padding: "5px 10px",
                          margin: "5px 10px",
                          width: open
                            ? "calc(100% - 30px)"
                            : "calc(100% - 20px)",
                          "&:hover": {
                            backgroundColor: "#F36421",
                          },
                        }}
                        onClick={() => {
                          navigate("/resources");
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#ffffff",
                          }}
                        >
                          <NoteAltIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Resources"}
                          sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                )}
                {school !== "dpsg" && (
                  <ListItem
                    key={"Feedback"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <Tooltip title={"Feedback"} placement="right" arrow>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          backgroundColor:
                            location.pathname === "/feedback"
                              ? "#F36421"
                              : "#00122E",
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: open ? "0px" : "5px",
                          borderBottomRightRadius: open ? "0px" : "5px",
                          padding: "5px 10px",
                          margin: "5px 10px",
                          width: open
                            ? "calc(100% - 30px)"
                            : "calc(100% - 20px)",
                          "&:hover": {
                            backgroundColor: "#F36421",
                          },
                        }}
                        onClick={() => {
                          navigate("/feedback");
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#ffffff",
                          }}
                        >
                          <ViewAgendaIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Feedback"}
                          sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                )}
                {school !== "dpsg" && (
                  <ListItem
                    key={"Attendance"}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <Tooltip title={"Attendance"} placement="right" arrow>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          backgroundColor:
                            location.pathname === "/student-attendance"
                              ? "#F36421"
                              : "#00122E",
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: open ? "0px" : "5px",
                          borderBottomRightRadius: open ? "0px" : "5px",
                          padding: "5px 10px",
                          margin: "5px 10px",
                          width: open
                            ? "calc(100% - 30px)"
                            : "calc(100% - 20px)",
                          "&:hover": {
                            backgroundColor: "#F36421",
                          },
                        }}
                        onClick={() => {
                          navigate("/student-attendance");
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#ffffff",
                          }}
                        >
                          <CalendarMonthIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Attendance"}
                          sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                )}
              </List>
              <Divider sx={{ backgroundColor: "#ffffff" }} />
            </div>
          )}
          <List>
            <ListItem
              key={"Account Settings"}
              disablePadding
              sx={{ display: "block" }}
            >
              <Tooltip title={"Account Settings"} placement="right" arrow>
                <ListItemButton
                  sx={{
                    display: "flex",
                    backgroundColor:
                      location.pathname === "/profile" ? "#F36421" : "#00122E",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    borderTopRightRadius: open ? "0px" : "5px",
                    borderBottomRightRadius: open ? "0px" : "5px",
                    padding: "5px 10px",
                    margin: "5px 10px",
                    width: open ? "calc(100% - 30px)" : "calc(100% - 20px)",
                    "&:hover": {
                      backgroundColor: "#F36421",
                    },
                  }}
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "#ffffff",
                    }}
                  >
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Account Settings"}
                    sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
            <ListItem key={"Logout"} disablePadding sx={{ display: "block" }}>
              <Tooltip title={"Logout"} placement="right" arrow>
                <ListItemButton
                  sx={{
                    display: "flex",
                    backgroundColor:
                      location.pathname === "/logout" ? "#F36421" : "#00122E",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    borderTopRightRadius: open ? "0px" : "5px",
                    borderBottomRightRadius: open ? "0px" : "5px",
                    padding: "5px 10px",
                    margin: "5px 10px",
                    width: open ? "calc(100% - 30px)" : "calc(100% - 20px)",
                    "&:hover": {
                      backgroundColor: "red",
                    },
                  }}
                  onClick={() => {
                    handleLogut();
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "#ffffff",
                    }}
                  >
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Logout"}
                    sx={{ opacity: open ? 1 : 0, fontWeight: 500 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </Drawer>
      </Box>
      {networkStatus.online ? children : <NoInternet />}
      <Box
        id="footer"
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          display: width > 600 ? "flex" : "none",
        }}
      >
        <Footer
          sx={{
            paddingLeft: open ? "270px" : width > 600 ? "100px" : "50px",
            display: width > 600 ? "flex" : "none",
          }}
        >
          <Typography>Innovartan©2024</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
              justifyContent: "space-between",
              width: width > 600 ? "300px" : "95%",
            }}
          >
            <Typography
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              onClick={() => {
                navigate("/terms-condition");
              }}
            >
              Terms & Conditions
            </Typography>
          </Box>
          {location.pathname !== "/innovartan-ai" && (
            <div style={{ position: "absolute", top: "-70px", right: "30px" }}>
              <AIButton />
            </div>
          )}
        </Footer>
      </Box>
    </Box>
  );
}
